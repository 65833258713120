// BillingForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import API_BASE_URL from '../config';

// Load Stripe instance with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function BillingForm({ onClose }) {
  const [amountToAdd, setAmountToAdd] = useState('');

  const handleAddBalance = async () => {
    const amount = parseFloat(amountToAdd);
    if (isNaN(amount) || amount < 5 || amount > 91) {
      alert('Please enter a valid amount between $5 and $91.');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/create-checkout-session`, { amount }, { withCredentials: true });
      const sessionId = response.data.id;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating Stripe Checkout session:', error);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="amount-to-add" className="block text-sm font-medium text-gray-700">
          Amount to Add
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            type="text"
            name="amount-to-add"
            id="amount-to-add"
            className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
            placeholder="0.00"
            pattern="^\d{1,2}(\.\d{1,2})?$"
            title="Please enter a valid amount between $5 and $91"
            value={amountToAdd}
            onChange={(e) => setAmountToAdd(e.target.value)}
          />
        </div>
      </div>
      <button
        type="button"
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        onClick={handleAddBalance}
      >
        Add Balance
      </button>
    </div>
  );
}

export default BillingForm;
