import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaPaperPlane, FaTrash, FaCopy, FaAngleDoubleLeft, FaAngleDoubleRight, FaSync } from 'react-icons/fa';
import SchemaTree from './SchemaTree';
import {Link} from "react-router-dom";
import API_BASE_URL from '../config';

function SQLChatbot({ databaseId }) {
  const [chatHistory, setChatHistory] = useState([]);
  const [userQuery, setUserQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [databaseName, setDatabaseName] = useState('Loading...');
  const [showAlert, setShowAlert] = useState(false);
  const [schemaData, setSchemaData] = useState([]);
  const chatHistoryRef = useRef(null);
  const [navigatorOpen, setNavigatorOpen] = useState(false);
  const [navigatorWidth, setNavigatorWidth] = useState(300);
  const navigatorRef = useRef(null);
  const [isSchemaLoading, setIsSchemaLoading] = useState(true);
  const [sliderBarColor, setSliderBarColor] = useState('bg-gray-200');
  const textareaRef = useRef(null);
  const [showInsufficientCreditsPopup, setShowInsufficientCreditsPopup] = useState(false); // Add this state variable


  const fetchChatHistory = () => {
    axios.get(`${API_BASE_URL}/api/chathistory/${databaseId}`, { withCredentials: true })
      .then((response) => {
        console.log('Final check - chat history:', response.data.chatHistory);
        setChatHistory(response.data.chatHistory);
        setDatabaseName(response.data.databaseName);
      })
      .catch((error) => {
        console.error('Error fetching chat history:', error);
      });
  };

  const fetchSchemaData = () => {
  setIsSchemaLoading(true);
  axios
    .get(`${API_BASE_URL}/api/schema_data/${databaseId}`, { withCredentials: true })
    .then((response) => {
      const schemaData = JSON.parse(response.data.schema_data);
      setSchemaData(schemaData);
      setIsSchemaLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching schema data:', error);
      setIsSchemaLoading(false);
      // Set the error state in the SchemaTree component
      setSchemaData({ error: 'Unable to load schema data' });
    });
};

  useEffect(() => {
    fetchChatHistory();
    fetchSchemaData();
  }, [databaseId]);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleUserInput = async (e) => {
    e.preventDefault();
    if (userQuery.trim() === '' || isInsufficientBalance) return;

    const newMessage = {
      content: userQuery,
      isUser: true,
    };

    setChatHistory((prevHistory) => [...prevHistory, newMessage]);
    setUserQuery('');

    // Scroll to the bottom of the chat window
    setTimeout(() => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    }, 0);

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/chatbot/${databaseId}`,
        { userQuery },
        { withCredentials: true }
      );

      const botResponse = {
        content: response.data.response,
        isUser: false,
      };

      setChatHistory((prevHistory) => [...prevHistory, botResponse]);

      // Fetch the updated chat history after sending the user query
      fetchChatHistory();
    } catch (error) {
      console.error('Error sending user query:', error);
      if (error.response && error.response.data.error === 'Insufficient balance') {
        setShowInsufficientCreditsPopup(true); // Show the popup when insufficient credits error occurs
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearChat = () => {
    setShowAlert(true);
  };

  const confirmClearChat = async () => {
  try {
    await axios.delete(`${API_BASE_URL}/api/chathistory/${databaseId}`, { withCredentials: true });
    setChatHistory([]);
    setShowAlert(false);
  } catch (error) {
    console.error('Error clearing chat history:', error);
  }
};

  const cancelClearChat = () => {
    setShowAlert(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };

  const handleSliderMouseDown = (e) => {
    const navigator = navigatorRef.current;
    if (navigator && navigatorOpen) {
      const startX = e.clientX;
      const startWidth = navigator.offsetWidth;

      const handleMouseMove = (e) => {
        const newWidth = Math.max(200, startWidth + e.clientX - startX);
        setNavigatorWidth(newWidth);
        setSliderBarColor('bg-gray-300');
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        setSliderBarColor('bg-gray-200');
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  const toggleNavigator = () => {
    setNavigatorOpen(!navigatorOpen);
  };

  const handleCloseNavigator = () => {
    setNavigatorOpen(false);
    setSliderBarColor('bg-gray-200');
  };

  const handleOpenNavigator = () => {
    setNavigatorOpen(true);
    setSliderBarColor('bg-gray-300');
  };

  const handleRefreshNavigator = () => {
    fetchSchemaData();
  };

  const handleSliderClick = (e) => {
    if (navigatorOpen) {
      e.stopPropagation();
    } else {
      toggleNavigator();
    }
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleUserInput(e);
    }
  };

  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);

  useEffect(() => {
  const fetchBalance = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/balance`, { withCredentials: true });
      const balance = response.data.balance;
      setIsInsufficientBalance(balance <= 0);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  fetchBalance();
}, []);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [userQuery]);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="flex items-center justify-between bg-blue-600 text-white p-6 shadow">
        <h2 className="text-2xl font-semibold">{databaseName}</h2>
        <button
          className="flex items-center space-x-2 bg-red-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300"
          onClick={handleClearChat}
        >
          <FaTrash className="w-4 h-4" />
          <span>Clear Chat</span>
        </button>
      </div>
      <div className="flex flex-1 overflow-hidden">
        <div
          className={`relative bg-white overflow-y-auto select-none transition-all duration-300 ease-in-out ${
            navigatorOpen ? '' : 'w-6'
          }`}
          style={{ width: navigatorOpen ? `${navigatorWidth}px` : '1.5rem' }}
          ref={navigatorRef}
        >
          {navigatorOpen && (
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <div className="flex space-x-2">
                  <button
                    className="bg-gray-200 text-gray-600 p-2 rounded-full hover:bg-gray-300 transition duration-300"
                    onClick={handleRefreshNavigator}
                  >
                    <FaSync className="w-4 h-4" />
                  </button>
                  <button
                    className="bg-gray-200 text-gray-600 p-2 rounded-full hover:bg-gray-300 transition duration-300"
                    onClick={handleCloseNavigator}
                  >
                    <FaAngleDoubleLeft className="w-4 h-4" />
                  </button>
                </div>
              </div>
              <SchemaTree
                  schemaData={schemaData}
                  databaseName={databaseName}
                  isLoading={isSchemaLoading}
                  error={schemaData.error}
                />
            </div>
          )}
          <div
            className={`absolute top-0 right-0 h-full w-6 rounded-l-lg ${sliderBarColor} cursor-pointer select-none z-10`}
            onMouseDown={navigatorOpen ? handleSliderMouseDown : null}
            onClick={handleSliderClick}
          >
            {!navigatorOpen && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <FaAngleDoubleRight className="text-gray-600" />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1 p-6">
          <div className="flex-1 overflow-y-auto pb-4" ref={chatHistoryRef}>
            <div className="flex flex-col space-y-6 max-w-4xl mx-auto">
              {chatHistory.map((message, index) => (
                <div key={index} className={`flex ${message.isUser ? 'justify-end' : 'justify-start'}`}>
                  <div
                    className={`p-4 rounded-lg shadow ${
                      message.isUser ? 'bg-blue-500 text-white' : 'bg-white text-gray-800'
                    }`}
                  >
                    <div className="whitespace-pre-wrap">{message.content}</div>
                    {!message.isUser && (
                      <button
                        className="mt-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                        onClick={() => copyToClipboard(message.content)}
                      >
                        <FaCopy className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="flex justify-start">
                  <div className="p-4 rounded-lg shadow bg-white text-gray-800">
                    <div className="flex items-center">
                      <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 animate-pulse"></div>
                      <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 animate-pulse animation-delay-75"></div>
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-pulse animation-delay-150"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
        <div className="flex items-center bg-white rounded-lg shadow-md max-w-4xl mx-auto">
          <textarea
            ref={textareaRef}
            value={userQuery}
            onChange={(e) => setUserQuery(e.target.value)}
            onKeyDown={handleTextareaKeyDown}
            onInput={adjustTextareaHeight}
            placeholder={isInsufficientBalance ? "Insufficient balance. Please add credits to continue." : "Type your message..."}
            className="flex-1 px-4 py-3 rounded-lg focus:outline-none bg-transparent resize-none overflow-auto transition-all duration-300"
            rows={1}
            style={{ maxHeight: '200px' }}
            disabled={isInsufficientBalance}
          ></textarea>
          <button
            type="button"
            onClick={handleUserInput}
            disabled={isLoading || isInsufficientBalance}
            className={`ml-4 text-blue-500 px-4 py-3 rounded-lg transition duration-300 ${
              isInsufficientBalance ? 'opacity-50 cursor-not-allowed' : 'hover:text-blue-600'
            }`}
          >
            <FaPaperPlane className="w-4 h-4" />
          </button>
        </div>
      </div>
        </div>
      </div>
      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Confirm Clear Chat</h3>
            <p className="mb-6">Are you sure you want to clear the chat history?</p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
                onClick={cancelClearChat}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                onClick={confirmClearChat}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      )}
      {showInsufficientCreditsPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Insufficient Credits</h3>
            <p className="mb-6">You don't have enough credits to continue chatting.</p>
            <div className="flex justify-end space-x-4">
              <Link
                to="/account"
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                View Credits
              </Link>
              <button
                className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
                onClick={() => setShowInsufficientCreditsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SQLChatbot;