// Account.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import BillingForm from './BillingForm';
import API_BASE_URL from '../config';

// Load Stripe instance with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Account() {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(0.0);
  const [showBillingPopup, setShowBillingPopup] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get('session_id');

    if (sessionId) {
      axios.post(`${API_BASE_URL}/api/balance`, { checkout_session_id: sessionId }, { withCredentials: true })
        .then(() => {
          fetchUserData();
          fetchBalance();
          window.history.replaceState({}, document.title, '/account');
        })
        .catch((error) => {
          console.error('Error adding balance:', error);
        });
    } else {
      fetchUserData();
      fetchBalance();
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/account`, { withCredentials: true });
      setUser(response.data.account);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/balance`, { withCredentials: true });
      setBalance(response.data.balance);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const handleAddBalance = () => {
    setShowBillingPopup(true);
  };

  const handleCloseBillingPopup = () => {
    setShowBillingPopup(false);
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container account-container">
      <div className="row mt-5">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Account Information</h5>
              <form>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={user.username}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={user.email}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="balance" className="form-label">
                    Balance
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="balance"
                    value={`$${balance.toFixed(2)}`}
                    readOnly
                  />
                </div>
              </form>
              <div className="mt-4">
                <button className="btn btn-primary" onClick={handleAddBalance}>
                  Add Balance
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBillingPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          <div className="bg-white rounded-lg p-6 z-10 w-full max-w-md">
            <h3 className="text-2xl font-bold mb-4">Add Balance</h3>
            <Elements stripe={stripePromise}>
              <BillingForm onClose={handleCloseBillingPopup} />
            </Elements>
            <button
              type="button"
              className="mt-4 px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              onClick={handleCloseBillingPopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Account;
