import { useState } from 'react'
import {Dialog, Disclosure, RadioGroup} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import dataServerIcon from '../assets/images/data-server.png';
import logo from '../assets/images/black companion-high-resolution-logo-black-transparent.png';
import serverLogo from '../assets/images/data-management.png'
import ideaLogo from '../assets/images/goodidea.png'
import cogLogo from '../assets/images/cogwheel.png'

import { useNavigate } from 'react-router-dom';
import {
  CheckIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  LightBulbIcon,
  ChatBubbleLeftRightIcon
} from "@heroicons/react/16/solid";
import {MinusSmallIcon, PlusSmallIcon} from "@heroicons/react/20/solid";

const navigation = [
  { name: 'Product', href: '#features2' },
  { name: 'Features', href: '#features' },
  { name: 'Pricing', href: '#pricing' }
]

const footerNavigation = [
  { name: 'Features', href: '#features' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'FAQ', href: '#faq' }
];

const features = [
  {
    name: 'Natural Language Interaction',
    description:
      'Interact with your databases using simple, intuitive natural language commands. No more complex SQL queries or syntax hassles. Companion understands your intent and translates it into efficient SQL statements.',
    href: '#',
    icon: dataServerIcon,
  },
  {
    name: 'Seamless Integration',
    description:
      'Effortlessly integrate Companion with your existing databases and workflows. Our application supports a wide range of database systems, ensuring compatibility and ease of use across different platforms.',
    href: '#',
    icon: cogLogo,
  },
  {
    name: 'Intelligent Insights',
    description:
      'Gain valuable insights into your data with Companion\'s intelligent analysis capabilities. Our application provides comprehensive reports, visualizations, and recommendations to help you make informed decisions.',
    href: '#',
    icon: ideaLogo,
  },
]

const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '#',
    price: { monthly: '$9', annually: '$90' },
    description: 'For individuals and small teams.',
    features: ['5 databases', 'Up to 1GB storage', 'Basic analytics', '24/7 support'],
    featured: false,
    cta: 'Get started',
  },
  {
    name: 'Pro',
    id: 'tier-pro',
    href: '#',
    price: { monthly: '$29', annually: '$290' },
    description: 'For growing businesses and teams.',
    features: [
      '20 databases',
      'Up to 10GB storage',
      'Advanced analytics',
      'Priority support',
      'Automatic backups',
    ],
    featured: true,
    cta: 'Get started',
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    price: 'Custom',
    description: 'For large organizations with advanced needs.',
    features: [
      'Unlimited databases',
      'Unlimited storage',
      'Advanced analytics and reporting',
      'Dedicated support',
      'Custom integrations',
      'Security and compliance features',
    ],
    featured: false,
    cta: 'Contact sales',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [frequency, setFrequency] = useState(frequencies[0])

  const navigate = useNavigate();

 const handleLogin = () => {
    navigate('/login');
  };

  const handleSignup = () => {
    navigate('/register');
  };

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Companion</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:items-center lg:space-x-4">
            <a
              href=""
              className="inline-flex items-center rounded-md bg-gray-200 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => handleLogin()}
            >
              Login
            </a>
            <a
              href=""
              className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => handleSignup()}
            >
              Get Started
            </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

       {/* Hero section */}
        <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
          <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
          />
          <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                Changing the way people interact with databases.
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                  Companion transforms database interaction with simple, natural language commands. Streamline your development with effortless, fast-paced management.
                </p>
                <div className="mt-10 flex flex-wrap items-center gap-x-6">
                  <div className="flex items-center">
                    <a
                      href="#"
                      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Get started
                    </a>
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900 ml-4">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
              <img
                src={serverLogo}
                alt=""
                className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-contain sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
              />
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>
        {/* END OF Hero section */}

        {/* Features section */}
        <div className="bg-white py-12 sm:py-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Streamline Your Database Management
              </h2>
            </div>
            <div className="mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
  <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
    {features.map((feature) => (
      <div key={feature.name} className="flex flex-col">
        <dt className="text-base font-semibold leading-7 text-gray-900">
          <img src={feature.icon} alt={feature.name} className="h-8 w-8 mb-4" aria-hidden="true" />
          {feature.name}
        </dt>
        <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p className="flex-auto">{feature.description}</p>
          <p className="mt-6">
            <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-600">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </p>
        </dd>
      </div>
    ))}
  </dl>
</div>
          </div>
        </div>
       {/* END OF Features section */}
      {/* Second Features section */}
    <div className="overflow-hidden bg-white py-24 sm:py-24" id="features2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:items-center">
          <div className="flex items-center justify-start lg:col-span-2">
            <div className="w-full h-full">
              <img
                src={require("../assets/images/Great-Example-Image.png")}
                alt="Product screenshot"
                className="w-full h-full rounded-xl shadow-xl ring-1 ring-gray-400/10"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div className="lg:col-span-1 lg:pl-8 lg:pt-4">
            <div className="lg:max-w-md">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Revolutionize Your Database Workflow</h2>
              <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Boost Productivity and Efficiency</p>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                Companion empowers you to streamline your database management process, saving you valuable time and resources. With our intuitive natural language interface and seamless integration, you can focus on what matters most - building great applications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* END OF Second Features section */}
      {/* Second Features section */}
    <div className="overflow-hidden bg-white py-24 sm:py-24" id="features2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:items-center">
          <div className="lg:col-span-1 lg:pr-8 lg:pt-4">
            <div className="lg:max-w-md">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Simple Connection</h2>
              <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Multiple Databases Supported</p>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                Companion seamlessly connects you to a world of data, supporting an extensive range of databases including MySQL, PostgreSQL, Oracle, MariaDB, SQL Server, and SQLite. Effortlessly manage your data across any platform with Companion's flexible and user-friendly interface—because great development knows no boundaries.              </p>
            </div>
          </div>
          <div className="flex items-center justify-end lg:col-span-2">
            <div className="w-full h-full">
              <img
                src={require("../assets/images/connection-image.png")}
                alt="Product screenshot"
                className="w-full h-full rounded-xl shadow-xl ring-1 ring-gray-400/10"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* END OF Second Features section */}
      {/* Third Features section */}
        <div className="bg-white py-12 sm:py-32" id="features">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-indigo-600">Unlock the Power of Your Data</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comprehensive Database Management</p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Companion provides a complete suite of tools to manage your databases efficiently. From data querying and manipulation to schema management and performance optimization, our platform covers all aspects of database administration.
                </p>
              </div>
              <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    Enhanced Security
                  </dt>
                  <dd className="mt-2">
                    Safeguard your sensitive data with Companion's robust security measures. Our platform employs encryption, secure authentication, and granular access control to ensure the highest level of data protection.
                  </dd>
                </div>
                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    Scalability and Performance
                  </dt>
                  <dd className="mt-2">
                    Companion scales effortlessly to handle the growth of your databases and user base. Our optimized infrastructure and intelligent query optimization techniques ensure lightning-fast performance, even with large datasets.
                  </dd>
                </div>
                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    Comprehensive Reporting
                  </dt>
                  <dd className="mt-2">
                    Generate comprehensive reports and visualizations to gain deep insights into your database performance, query patterns, and resource utilization. Companion's reporting tools empower you to make data-driven decisions and optimize your database operations.
                  </dd>
                </div>
                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    Expert Support
                  </dt>
                  <dd className="mt-2">
                    Get expert assistance whenever you need it with Companion's dedicated support team. Our knowledgeable professionals are ready to help you with any questions, troubleshooting, or guidance to ensure a smooth and efficient database management experience.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      {/* END OF Third Features section */}

      {/* Social Proof */}
<div className="bg-white py-10 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
      Here's what our customers say about Companion
    </h2>
    <div className="mx-auto mt-10 max-w-xl">
      <h3 className="text-center text-xl font-semibold leading-8 text-gray-900">
        Trusted by innovative teams
      </h3>
      <div className="mt-12 grid grid-cols-2 items-center gap-x-8 gap-y-12 sm:grid-cols-4 sm:gap-x-10 lg:grid-cols-5">
        <img
          className="col-span-1 max-h-16 w-full object-contain"
          src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
          alt="Transistor"
          width={158}
          height={48}
        />
        <img
          className="col-span-1 max-h-16 w-full object-contain"
          src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
          alt="Reform"
          width={158}
          height={48}
        />
        <img
          className="col-span-1 max-h-16 w-full object-contain"
          src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
          alt="Tuple"
          width={158}
          height={48}
        />
        <img
          className="col-span-1 max-h-16 w-full object-contain"
          src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
          alt="SavvyCal"
          width={158}
          height={48}
        />
        <img
          className="col-span-1 max-h-16 w-full object-contain"
          src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
          alt="Statamic"
          width={158}
          height={48}
        />
      </div>
    </div>
    <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
      <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
        <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
          <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
            <blockquote className="text-gray-900">
              <p>"Companion has simplified our database management process. The natural language interaction is intuitive and saves us time. It's a valuable addition to our toolkit."</p>
            </blockquote>
            <figcaption className="mt-6 flex items-center gap-x-4">
              <img className="h-10 w-10 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <div>
                <div className="font-semibold text-gray-900">Sarah Johnson</div>
                <div className="text-gray-600">@sarahjohnson</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
          <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
            <blockquote className="text-gray-900">
              <p>"The seamless integration with our existing databases and the comprehensive reporting capabilities have made Companion an essential part of our workflow. It has significantly improved our productivity and helped us make data-driven decisions faster than ever before. The platform's performance and scalability have been impressive, allowing us to handle the growth of our databases with ease."</p>
            </blockquote>
            <figcaption className="mt-6 flex items-center gap-x-4">
              <img className="h-10 w-10 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <div>
                <div className="font-semibold text-gray-900">Michael Thompson</div>
                <div className="text-gray-600">@michaelthompson</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
          <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
            <blockquote className="text-gray-900">
              <p>"Companion's expert support team has been incredible. They were knowledgeable and responsive, ensuring a smooth onboarding process for our team."</p>
            </blockquote>
            <figcaption className="mt-6 flex items-center gap-x-4">
              <img className="h-10 w-10 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <div>
                <div className="font-semibold text-gray-900">Emily Davis</div>
                <div className="text-gray-600">@emilydavis</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
          <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
            <blockquote className="text-gray-900">
              <p>"Companion has revolutionized our database management. The natural language interaction is a game-changer, making it incredibly easy for our team to work with complex data structures. The platform's scalability and performance have been outstanding, allowing us to handle the growth of our databases seamlessly. It's a must-have tool for any data-driven organization looking to boost productivity and make informed decisions quickly."</p>
            </blockquote>
            <figcaption className="mt-6 flex items-center gap-x-4">
              <img className="h-10 w-10 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <div>
                <div className="font-semibold text-gray-900">David Wilson</div>
                <div className="text-gray-600">@davidwilson</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
          <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
            <blockquote className="text-gray-900">
              <p>"Companion has been a lifesaver for our data analytics team. The intelligent insights and reporting features have provided us with valuable information to optimize our database performance."</p>
            </blockquote>
            <figcaption className="mt-6 flex items-center gap-x-4">
              <img className="h-10 w-10 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1514222709107-a180c68d72b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <div>
                <div className="font-semibold text-gray-900">Olivia Anderson</div>
                <div className="text-gray-600">@oliviaanderson</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div className="pt-8 sm:inline-block sm:w-full sm:px-4">
          <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
            <blockquote className="text-gray-900">
              <p>"I can't imagine managing our databases without Companion. The enhanced security features give us peace of mind, and the granular access control is top-notch."</p>
            </blockquote>
            <figcaption className="mt-6 flex items-center gap-x-4">
              <img className="h-10 w-10 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <div>
                <div className="font-semibold text-gray-900">Sophia Martinez</div>
                <div className="text-gray-600">@sophiamartinez</div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>
{/* END OF Social Proof */}

       {/* FAQ */}
        <div className="bg-white" id="faq">
        <div className="mx-auto max-w-7xl px-6 py-9 sm:py-32 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              <Disclosure as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">What databases does Companion support?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">Companion supports a wide range of popular databases, including PostgreSQL, MySQL, Microsoft SQL Server, and Oracle. It seamlessly integrates with your existing database infrastructure.</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Is my data secure with Companion?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">Absolutely. At Companion, we prioritize the security of your data. We employ industry-standard encryption, secure authentication mechanisms, and follow best practices to ensure the confidentiality and integrity of your information.</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">How does the natural language interaction work?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">Companion uses advanced natural language processing techniques to understand your queries and commands. Simply type your request in plain English, and our intelligent algorithms will interpret your intent and translate it into the appropriate SQL statements.</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Can I try Companion before committing to a subscription?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">Yes! We offer a free trial period so you can explore Companion's features and evaluate its performance with your databases. Simply sign up for an account and start using Companion without any upfront commitment.</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">What kind of support do you provide?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">We offer comprehensive support to ensure your success with Companion. Our knowledgeable support team is available to assist you with any questions, troubleshooting, or guidance you may need. We provide email support, online documentation, and a community forum where you can connect with other Companion users.</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </dl>
          </div>
        </div>
      </div>
      {/* END OF FAQ */}
      {/* PRICING */}
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl" id="pricing">
              Simple and Flexible Pricing
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Pay only for what you use with our transparent pay-as-you-go pricing model.
          </p>
          <div className="mx-auto mt-16 max-w-md sm:mt-20 lg:mt-24">
            <div className="rounded-3xl bg-gradient-to-br from-blue-400 to-blue-600 p-8 ring-1 ring-blue-400 shadow-lg xl:p-10">
              <h3 id="pay-as-you-go" className="text-lg font-semibold leading-8 text-white">
                Pay-as-you-go
              </h3>
              <p className="mt-4 text-sm leading-6 text-blue-100">
                Ideal for businesses of all sizes with flexible usage needs.
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-white">$0.01</span>
                <span className="text-sm font-semibold leading-6 text-blue-100">/API call</span>
              </p>
              <a
                href="#"
                aria-describedby="pay-as-you-go"
                className="mt-6 block rounded-md bg-white py-2 px-3 text-center text-sm font-semibold leading-6 text-blue-600 shadow-md hover:bg-blue-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Get Started
              </a>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-blue-100 xl:mt-10">
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                  No minimum commitment
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                  Pay only for the API calls you make
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                  Seamless scalability for your projects
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                  24/7 customer support
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* END OF PRICING */}

      {/* FOOTER */}
      <footer className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
          <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
            {footerNavigation.map((item) => (
              <div key={item.name} className="pb-6">
                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
          <p className="mt-10 text-center text-xs leading-5 text-gray-500">
            &copy; 2024 Companion, Inc. All rights reserved.
          </p>
        </div>
      </footer>
      {/* END OF FOOTER */}
    </div>


  )
}
