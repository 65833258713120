import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../assets/css/forgot-password-style.css';
import API_BASE_URL from '../config';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/forgot-password`, { email });
      setMessage(response.data.message);
      console.log(response.data.message);
    } catch (error) {
      console.error(error);
      setMessage(error.response?.data?.error || 'An error occurred');
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="forgot-password-container">
      <form className="forgot-password-form" onSubmit={handleSubmit}>
        <h1>Forgot Password</h1>
        <div className="forgot-password-input-box">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="forgot-password-btn">Reset Password</button>
        {message && <p className="forgot-password-message">{message}</p>}
        <button type="button" className="forgot-password-back-btn" onClick={handleBackToLogin}>
          Back to Login
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
