import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Databases from './components/Databases';
import PrivateRoute from './components/PrivateRoute';
import ConnectDatabase from './components/ConnectDatabase';
import Account from './components/Account';
import SQLChatbot from "./components/SQLChatbot";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import LandingPage from "./components/LandingPage";
import DatabaseProperties from './components/DatabaseProperties';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/databases" element={<PrivateRoute element={Databases} />} />
        <Route path="/connect" element={<PrivateRoute element={ConnectDatabase} />} />
        <Route path="/account" element={<PrivateRoute element={Account} />} />
        <Route path="/chatbot/:databaseId" element={<PrivateRoute element={SQLChatbot} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/properties/:databaseId" element={<PrivateRoute element={DatabaseProperties} />} />
      </Routes>
    </Router>
  );
}

export default App;
