import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import API_BASE_URL from '../config';

// Import database logos
import mariadbLogo from '../assets/images/sql logos/mariadb.png';
import sqlserverLogo from '../assets/images/sql logos/microsoft-sql-server.png';
import mysqlLogo from '../assets/images/sql logos/mysql.png';
import oracleLogo from '../assets/images/sql logos/oracle.png';
import postgresqlLogo from '../assets/images/sql logos/postgresql.png';
import sqliteLogo from '../assets/images/sql logos/sqlite.png';

// Mapping database types to their respective logos
const dbIcons = {
  MySQL: mysqlLogo,
  PostgreSQL: postgresqlLogo,
  'SQL Server': sqlserverLogo,
  Oracle: oracleLogo,
  MariaDB: mariadbLogo,
  SQLite: sqliteLogo,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Databases() {
  const [databases, setDatabases] = useState([]);

  useEffect(() => {
    fetchDatabases();
  }, []);

  const fetchDatabases = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/databases`, { withCredentials: true });
      console.log("API Response:", response.data);
      if (response.data.databases) {
        setDatabases(response.data.databases);
      } else {
        console.error('Data not received as expected:', response.data);
      }
    } catch (error) {
      console.error("Error fetching databases:", error);
    }
  };

  const handleDeleteDatabase = async (databaseId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/databases/${databaseId}`, { withCredentials: true });
      fetchDatabases();
    } catch (error) {
      console.error("Error deleting database:", error);
    }
  };

  return (
    <div className="py-10">
      <header>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Connected Databases</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          {databases.length > 0 ? (
            <ul role="list" className="divide-y divide-gray-100">
              {databases.map((db) => (
                <li key={db.id} className="flex items-center justify-between gap-x-6 py-5">
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <img src={dbIcons[db.db_type] || dbIcons.default} alt={db.db_type || 'Database'} className="h-12 w-12" />
                      <p className="text-sm font-semibold leading-6 text-gray-900">{db.display_name || 'No Name'}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">{db.db_type || 'Unknown Type'}</p>
                      <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                        <circle cx="1" cy="1" r="1" />
                      </svg>
                      <p className="truncate">Host: {db.host}</p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Link
                      to={`/properties/${db.id}`}
                      className="rounded-md bg-gray-300 px-2 py-1 text-xs font-semibold text-gray-700 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300 sm:block transition duration-150 ease-in-out"
                    >
                      Properties
                    </Link>
                    <Link
                      to={`/chatbot/${db.id}`}
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:block transition duration-150 ease-in-out"
                    >
                      Chat<span className="sr-only">, {db.display_name}</span>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-center text-gray-500">No databases connected.</p>
          )}
          <div className="mt-6 flex justify-center">
            <Link
              to="/connect"
              className="relative block w-1/2 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
              </svg>
              <span className="mt-2 block text-sm font-semibold text-gray-900">Connect New Database</span>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Databases;
