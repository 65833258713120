import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../assets/images/black companion-high-resolution-logo-black-transparent.png';
import '../assets/css/login-style.css';
import { useNavigate, useLocation } from 'react-router-dom';
import API_BASE_URL from '../config';

function Login() {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const error = searchParams.get('error');
  const message = searchParams.get('message');

  useEffect(() => {
    if (error === 'token_expired') {
      setErrorMessage('Verification token expired. Please register again.');
    } else if (error === 'invalid_token') {
      setErrorMessage('Invalid verification token. Please register again.');
    } else if (error === 'user_data_not_found') {
      setErrorMessage('User data not found. Please register again.');
    } else if (message === 'email_verified') {
      setErrorMessage('Email verified. Please log in.');
    }
  }, [error, message]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/login`, {
        username_or_email: usernameOrEmail,
        password,
      }, { withCredentials: true });
      console.log("Login response:", response);
      navigate('/databases', { replace: true });
    } catch (error) {
      console.error("Login error:", error);
      if (error.response && error.response.data.error.includes('Account not verified.')) {
        setErrorMessage('Account not verified. Please check your email to verify your account.');
        setUnverifiedEmail(error.response.data.email);
        setShowModal(true);
      } else {
        setErrorMessage('Invalid username, email, or password');
      }
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const resendVerificationEmail = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/resend-verification`, { email: unverifiedEmail });
      setShowModal(false);
      setErrorMessage('Verification email resent. Please check your email.');
    } catch (error) {
      console.error("Resend verification error:", error);
      setErrorMessage('Failed to resend verification email. Please try again.');
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-logo-container">
          <img src={logo} alt="CompAnIon Logo" />
        </div>

        <div className="login-input-box">
          <input
            type="text"
            className="form-control"
            id="usernameOrEmail"
            name="usernameOrEmail"
            placeholder="Username or Email"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
            required
          />
          <i className='bx bxs-user'></i>
        </div>

        <div className="login-input-box">
          <input
            type={showPassword ? 'text' : 'password'}
            className="form-control"
            id="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <i className='bx bxs-lock-alt'></i>
          <span className="login-password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? 'Hide' : 'Show'}
          </span>
        </div>

        {errorMessage && (
          <div className="login-error-message">
            <p>{errorMessage}</p>
          </div>
        )}

        <button type="submit" className="login-btn" disabled={loading}>
          {loading ? (
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          ) : (
            'Login'
          )}
        </button>

        <div className="register-link">
          <p>Don't have an account? <a href="/register">Register</a></p>
        </div>
        <div className="forgot-password-link">
          <a href="/forgot-password">Forgot Password?</a>
        </div>
      </form>

      {showModal && (
        <div className="login-modal">
          <div className="login-modal-content">
            <h3>Account Not Verified</h3>
            <p>Your account is not verified. Please check your email to verify your account.</p>
            <div className="login-modal-buttons">
              <button className="login-modal-button" onClick={resendVerificationEmail}>
                Resend Verification Email
              </button>
              <button className="login-modal-button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
