import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../assets/css/reset-password-style.css';
import API_BASE_URL from '../config';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    if (!passwordRegex.test(password)) {
      setMessage(
        'Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character'
      );
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/reset-password/${token}`, { password });
      setMessage('Password reset successfully. Please log in.');
      console.log(response.data.message);
      navigate('/login');
    } catch (error) {
      console.error(error);
      setMessage(error.response?.data?.error || 'An error occurred');
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <h1>Reset Password</h1>
        <div className="password-requirements">
          Please enter a password that meets all of the requirements below:
          <ul>
            <li>8 characters minimum</li>
            <li>1 number</li>
            <li>1 special character</li>
            <li>1 uppercase letter</li>
          </ul>
        </div>

        <div className="reset-password-input-box">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="reset-password-input-box">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span className="reset-password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? 'Hide' : 'Show'}
          </span>
        </div>

        {message && <p className={`reset-password-message ${message.includes('success') ? 'success' : 'error'}`}>{message}</p>}

        <button type="submit" className="reset-password-btn">Reset Password</button>
        <button type="button" className="reset-password-back-btn" onClick={handleBackToLogin}>
          Back to Login
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
