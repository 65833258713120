import React, { useState } from 'react';
import { FaDatabase, FaTable, FaColumns } from 'react-icons/fa';
import API_BASE_URL from '../config';
const SchemaTree = ({ schemaData, isLoading, error }) => {
  const [openTables, setOpenTables] = useState({});

  const toggleTable = (tableName) => {
    setOpenTables(prev => ({
      ...prev,
      [tableName]: !prev[tableName]
    }));
  };

  return (
    <div className="schema-tree overflow-y-auto max-h-full">
      {error ? (
        <div className="flex justify-center items-center h-20">
          <span className="text-red-500">{error}</span>
        </div>
      ) : isLoading ? (
        <div className="flex justify-center items-center h-20">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <ul className="space-y-2">
          <li className="flex items-center space-x-2">
            <FaDatabase className="text-blue-500" />
            <span className="font-bold">{schemaData.database_name}</span>
          </li>
          {Object.entries(schemaData.tables).map(([tableName, tableData]) => (
            <li key={tableName} className="ml-4">
              <div
                className="flex items-center space-x-2 cursor-pointer hover:text-blue-500"
                onClick={() => toggleTable(tableName)}
              >
                <FaTable className="text-green-500" />
                <span className="font-bold">{tableName}</span>
              </div>
              {openTables[tableName] && (
                <ul className="ml-8 mt-2 space-y-1">
                  <li className="flex items-center space-x-2">
                    <FaColumns className="text-yellow-500" />
                    <span className="font-semibold">Columns</span>
                  </li>
                  {Object.entries(tableData.columns).map(([columnName, columnType]) => (
                    <li key={columnName} className="ml-4 flex items-center space-x-2">
                      <span className="column-name">{columnName}</span>
                      <span className="column-type text-gray-500">{columnType}</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SchemaTree;