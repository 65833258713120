// frontend/src/components/PrivateRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { isLoggedIn } from '../auth';
import Navbar from './Navbar';
import API_BASE_URL from '../config';
function PrivateRoute({ element: Component, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    const checkAuth = async () => {
      const loggedIn = await isLoggedIn();
      setIsAuthenticated(loggedIn);
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>
  }

  return isAuthenticated ? (
    <>
      <Navbar />
      <Component {...rest} {...params} />
    </>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;