import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid';
import mariadbLogo from '../assets/images/sql logos/mariadb.png';
import mysqlLogo from '../assets/images/sql logos/mysql.png';
import postgresqlLogo from '../assets/images/sql logos/postgresql.png';
import { XMarkIcon } from "@heroicons/react/24/outline";
import API_BASE_URL from '../config';
import axios from "axios";

const steps = [
  { id: 'name', name: 'Database Name', description: 'Enter a name for your database' },
  { id: 'type', name: 'Database Type', description: 'Select the type of database you are using' },
  { id: 'configuration', name: 'Configuration', description: 'Enter your database connection details' },
];

const databases = [
  { name: 'MySQL', icon: mysqlLogo },
  { name: 'PostgreSQL', icon: postgresqlLogo },
  { name: 'MariaDB', icon: mariadbLogo },
];

function ConnectDatabase() {
  const [currentStep, setCurrentStep] = useState(0);
  const [dbName, setDbName] = useState('');
  const [dbType, setDbType] = useState('');
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [database, setDatabase] = useState('');
  const navigate = useNavigate();
  const dbTypeRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleNext = (e) => {
    e.preventDefault();
    console.log(`Current step: ${steps[currentStep].id}, dbType: '${dbType}'`);
    if (steps[currentStep].id === 'type' && dbType === '') {
      alert('Please select a database type to continue.');
      return;
    }
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      handleSubmit(e);
    }
  };

  const handleCancel = () => {
    navigate('/databases');
  };

  const handleBack = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => {
      const newStep = prevStep - 1;
      if (newStep === 0) {
        setDbType('');
      }
      return newStep;
    });
  };

  const handleDbTypeClick = (dbName) => {
    setDbType(dbName);
  };

  const handleOutsideClick = (e) => {
    if (dbTypeRef.current && !dbTypeRef.current.contains(e.target)) {
      // setDbType('');
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

    const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting connection details to the backend");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/connect`,
        {
          host,
          port,
          username,
          password,
          database,
          dbType,
          displayName: dbName,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        navigate('/databases');
      } else {
        throw new Error('Failed to connect to the database');
      }
    } catch (error) {
      console.error('Error: ', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setErrorMessage(error.response.data.error || 'Failed to connect to the database');
      } else if (error.request) {
        // The request was made but no response was received
        setErrorMessage('No response received from the server');
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorMessage('Error connecting to the database. Please check your connection details.');
      }
    }
  };

  const renderError = () => (
    <div className="flex items-center bg-red-100 border border-red-400 text-red-700 rounded-md relative py-3 px-4" role="alert">
      <div className="flex-1">
        <div className="font-semibold">Error!</div>
        <div className="text-sm">{errorMessage}</div>
      </div>
      <button
        onClick={() => setErrorMessage('')}
        aria-label="Close"
        className="ml-4 flex-shrink-0 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
      >
        <XMarkIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      </button>
    </div>
  );

  const renderStep = () => {
    switch (steps[currentStep].id) {
      case 'name':
        return (
          <div>
            <label htmlFor="dbName" className="block text-sm font-medium leading-6 text-gray-900">
              Database Name - (This will be the name displayed on Companion)
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="dbName"
                id="dbName"
                value={dbName}
                required
                onChange={(e) => setDbName(e.target.value)}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter a name for your database"
              />
            </div>
          </div>
        );
      case 'type':
        return (
          <div ref={dbTypeRef}>
            <label className="block text-sm font-medium leading-6 text-gray-900">Select Database Type</label>
            <div className="mt-4 grid grid-cols-3 gap-4">
              {databases.map((db) => (
                <div
                  key={db.name}
                  className={`relative cursor-pointer ${dbType === db.name ? 'bg-gray-200 rounded-lg' : ''}`}
                  onClick={() => handleDbTypeClick(db.name)}
                >
                  <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg flex items-center justify-center">
                    <img src={db.icon} alt={db.name} className="pointer-events-none object-contain group-hover:opacity-75 h-20" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case 'configuration':
        return (
          <div className="space-y-4">
            <div>
              <label htmlFor="host" className="block text-sm font-medium leading-6 text-gray-900">
                Host
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="host"
                  id="host"
                  required
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter the database host"
                />
              </div>
            </div>
            <div>
              <label htmlFor="port" className="block text-sm font-medium leading-6 text-gray-900">
                Port
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="port"
                  id="port"
                  required
                  value={port}
                  onChange={(e) => setPort(e.target.value)}
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter the database port"
                />
              </div>
            </div>
            <div>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Username
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="username"
                  id="username"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter the database username"
                />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter the database password"
                />
              </div>
            </div>
            <div>
              <label htmlFor="database" className="block text-sm font-medium leading-6 text-gray-900">
                Database Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="database"
                  required
                  id="database"
                  value={database}
                  onChange={(e) => setDatabase(e.target.value)}
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter the database name"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 py-12">
      {/* Error Message */}
      {errorMessage && renderError()}
      {/* HEADING SECTION */}
      <div className="bg-gray-100 px-6 py-8 sm:py-12 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">Database Connection</h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Follow the steps below to connect your database and start exploring your data.
          </p>
        </div>
      </div>

      <div className="flex w-full max-w-4xl mx-auto mt-8 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col w-full">
          <div className="flex flex-col sm:flex-row">
            {/* STEPS */}
            <nav aria-label="Progress" className="w-full sm:w-1/3 sm:mr-8 mb-8 sm:mb-0">
              <ol role="list" className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                  <li key={step.name} className={`${stepIdx !== steps.length - 1 ? 'pb-10' : ''} relative`}>
                    {stepIdx < currentStep ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                        ) : null}
                        <div className="group relative flex items-center opacity-50 cursor-default">
                          <span className="flex h-9 items-center">
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 group-hover:bg-gray-400">
                              <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-gray-500">{step.name}</span>
                            <span className="text-sm text-gray-400">{step.description}</span>
                          </span>
                        </div>
                      </>
                    ) : stepIdx === currentStep ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                        ) : null}
                        <div className="group relative flex items-center" aria-current="step">
                          <span className="flex h-9 items-center" aria-hidden="true">
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                              <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-indigo-600">{step.name}</span>
                            <span className="text-sm text-gray-500">{step.description}</span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                        ) : null}
                        <div className="group relative flex items-center opacity-70 cursor-default">
                          <span className="flex h-9 items-center" aria-hidden="true">
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-300">
                              <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-transparent" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-gray-400">{step.name}</span>
                            <span className="text-sm text-gray-400">{step.description}</span>
                          </span>
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ol>
            </nav>

            {/* CONNECTION SECTION */}
            <div className="w-full sm:w-2/3">
              <form onSubmit={handleNext}>
                {renderStep()}
                <div className="mt-8 flex justify-between">
                  {currentStep > 0 && (
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                  )}
                  <div className="flex space-x-4">
                    {currentStep === 0 && (
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleCancel}
                      >
                        <XMarkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Cancel
                      </button>
                    )}
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {currentStep < steps.length - 1 ? 'Next' : 'Connect'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectDatabase;
