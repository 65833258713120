import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/black companion-high-resolution-logo-black-transparent.png';
import '../assets/css/register-style.css';
import API_BASE_URL from '../config';

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Form submitted with:', { username, email, password, confirmPassword });

    // Password validation
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      console.error('Passwords do not match');
      return;
    }

    // Default password requirements
    const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        'Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character'
      );
      console.error('Password does not meet requirements');
      return;
    }

    setLoading(true); // Start the loading spinner

    try {
      const response = await axios.post(`${API_BASE_URL}/api/register`, { username, email, password });
      console.log('Registration response:', response.data);

      // Show the modal
      setShowModal(true);
    } catch (error) {
      console.error('Registration error:', error);
      if (error.response) {
        const { data } = error.response;
        if (data.error === 'Username already exists') {
          setUsernameError('Username already exists');
        } else if (data.error === 'Email already exists') {
          setEmailError('Email already exists');
        }
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate('/login');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <div className="register-logo-container">
          <img src={logo} alt="CompAnIon Logo" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="register-input-box">
            <input
              type="text"
              className={`form-control ${usernameError ? 'is-invalid' : ''}`}
              id="username"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setUsernameError('');
              }}
              required
            />
            <i className="bx bxs-user"></i>
            {usernameError && <div className="register-error-message">{usernameError}</div>}
          </div>

          <div className="register-input-box">
            <input
              type="email"
              className={`form-control ${emailError ? 'is-invalid' : ''}`}
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError('');
              }}
              required
            />
            <i className="bx bxs-envelope"></i>
            {emailError && <div className="register-error-message">{emailError}</div>}
          </div>

          <div className="password-requirements">
            Please enter a password that meets all of the requirements below:
            <ul>
              <li>8 characters minimum</li>
              <li>1 number</li>
              <li>1 special character</li>
              <li>1 uppercase letter</li>
            </ul>
          </div>

          <div className="register-input-box">
            <input
              type={showPassword ? 'text' : 'password'}
              className={`form-control ${passwordError ? 'is-invalid' : ''}`}
              id="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <i className="bx bxs-lock-alt"></i>
            <span className="register-password-toggle" onClick={togglePasswordVisibility}>
              {showPassword ? 'Hide' : 'Show'}
            </span>
          </div>

          <div className="register-input-box">
            <input
              type={showPassword ? 'text' : 'password'}
              className={`form-control ${passwordError ? 'is-invalid' : ''}`}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <i className="bx bxs-lock-alt"></i>
          </div>

          {passwordError && <div className="register-error-message text-danger">{passwordError}</div>}

          <button type="submit" className="register-btn" disabled={loading}>
            {loading ? (
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            ) : (
              'Sign up'
            )}
          </button>
        </form>

        <div className="login-link">
          <p>
            Already have an account? <a href="/login">Login</a>
          </p>
        </div>
      </div>

      {showModal && (
        <div className="register-modal">
          <div className="register-modal-content">
            <h3>Registration Successful</h3>
            <p>Please check your email to verify your account before logging in.</p>
            <button className="register-modal-button" onClick={closeModal}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
