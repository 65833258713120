// frontend/src/auth.js
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const isLoggedIn = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/is_authenticated`, { withCredentials: true });
    return response.data.is_authenticated;
  } catch (error) {
    console.error('Error checking authentication:', error);
    return false;
  }
};
