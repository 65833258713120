// frontend/src/components/DatabaseProperties.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';

const DatabaseProperties = () => {
  const { databaseId } = useParams();
  const [databaseInfo, setDatabaseInfo] = useState(null);
  const [newDisplayName, setNewDisplayName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDatabaseInfo = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/databases/${databaseId}`, { withCredentials: true });
        setDatabaseInfo(response.data);
        setNewDisplayName(response.data.display_name);
      } catch (error) {
        console.error('Error fetching database information:', error);
      }
    };

    fetchDatabaseInfo();
  }, [databaseId]);

  const handleUpdateDisplayName = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/api/databases/${databaseId}/rename`, { displayName: newDisplayName }, { withCredentials: true });
      alert('Display name updated successfully!');
      setDatabaseInfo({ ...databaseInfo, display_name: newDisplayName });
    } catch (error) {
      console.error('Error updating display name:', error);
    }
  };

  const handleDeleteDatabase = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this database?');
    if (confirmDelete) {
      try {
        await axios.delete(`${API_BASE_URL}/api/databases/${databaseId}`, { withCredentials: true });
        navigate('/databases'); // Navigate back to the databases list
      } catch (error) {
        console.error('Error deleting database:', error);
      }
    }
  };

  if (!databaseInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="py-24">
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Database Information</h3>
            <p className="mt-1 text-sm text-gray-500">Details about the selected database.</p>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Display Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{databaseInfo.display_name}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Database Type</dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{databaseInfo.db_type}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Host</dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{databaseInfo.host}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Port</dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{databaseInfo.port}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Username</dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{databaseInfo.username}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Database Name</dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{databaseInfo.database_name}</dd>
              </div>
            </dl>
          </div>
          <form onSubmit={handleUpdateDisplayName} className="mt-6 px-4 py-6 sm:px-6 flex justify-end">
            <input
              type="text"
              value={newDisplayName}
              onChange={e => setNewDisplayName(e.target.value)}
              placeholder="New Display Name"
              className="mr-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="submit"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Update Name
            </button>
          </form>
          <div className="px-4 py-6 sm:px-6 flex justify-end">
            <button
              className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={handleDeleteDatabase}
            >
              Delete Database
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatabaseProperties;
